
import ValidationMixin from "@/mixins/validation";
import { SiteTypes, SiteStation } from "@/store/modules/site/site.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const stationX = namespace("Site");

@Component({})
export default class EditSite extends Mixins(ValidationMixin) {
  @stationX.State(SiteTypes.DELETE_SITE_STATE)
  public deleteStation!: DefaultState;

  @stationX.State(SiteTypes.SITE_DATA)
  public userStation!: SiteStation;

  @stationX.Mutation(SiteTypes.SET_DELETE_SITE_DIALOG)
  public setDeleteStation!: (deleteStation: boolean) => void;

  @stationX.Action(SiteTypes.DELETE_SITE)
  public deleteSiteStation!: () => Promise<void>;

  public updateStationStep = 1;

  public isValid = false;

  @Ref("form") public formRef!: Validator;

  closeDeleteStationDialog(): void {
    this.setDeleteStation(false);
  }
}
